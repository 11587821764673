<template>
  <div>
    {{ getMessageWithReplace('priceinfotext', {'price' : price}) }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'bottom-info-text-text-one',
  computed: {
    ...mapGetters(['getAppLanguage', 'getMessage', 'getMessageWithReplace']),
  },
  data() {
    let formatter =  new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    return {
      price: formatter.format(process.env.VUE_APP_PRICE)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
