<template>
  <div class="cart-item">
    <div class="row">
      <div class="col-md-8">
        <div class="pname" v-if="item.name === 'Umweltplakette Österreich'">{{ getMessage('environmentalstickeraustria') }}</div>
        <div class="pname" v-else>{{ item.name }}</div>
        <div class="pvin">VIN: {{ item.vin }} <br/> {{ item.vendor }} | {{ item.fueltype }}  </div>
        <div :hidden="isCheckout"><a href="#" v-on:click="(e) => removeItem(item.vin)">{{ getMessage('delete') }} <font-awesome-icon style="font-size: 14px; color: #11843D;" icon="fa fa-trash" /></a>&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div class="col-md-4 text-end">
        <div class="pprice"> {{ $n(item.price_without_tax, 'currency', 'de') }} &nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'cart-item',
  props: {
    item: {},
    isCheckout: null
  },
  computed: {
    ...mapGetters(['getMessage']),
  },
  methods: {
    removeItem(vin) {
      this.loading = true;
      this.$store.dispatch("removeCartItem", vin).then(() => {
        // should be remove from cart ?
        window.dataLayer.push({ecommerce: null});
        window.dataLayer.push({
          event: "addToCart",
          'ecommerce': {
            'currencyCode': 'EUR',
            'add': {                                // 'add' actionFieldObject measures.
              'products': [{                        //  adding a product to a shopping cart.
                'name': 'Plaque',
                'id': vin,
                'price': process.env.VUE_APP_PRICE,
                'category': 'Plaque',
                'quantity': 1
              }]
            }
          }});
        this.loading = false
      }, error => {
        //@TODO @Thomas -> bitte fehlermeldung -> Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen (in ROT und hübsch)
        console.error(error);
        this.loading = false;
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
